import { Component, Input } from '@angular/core';
import { ConfigQuestion } from '../../models/config-question.interface';
import { FormGroup } from '@angular/forms';
import { ConfigQuestionType } from '../../constants/enum.const';
import { ConfigUtil } from '../../utils/config.util';
import { Entity } from '../../models/entity.model';

@Component({
	selector: 'bcb-component-wrapper',
	templateUrl: './component-wrapper.component.html',
	styleUrls: ['./component-wrapper.component.scss'],
})
export class ComponentWrapperComponent {
	@Input() questions!: Array<ConfigQuestion>;
	@Input() formGroup!: FormGroup;
	@Input() visibleControls!: Set<string>;
	@Input() questionsConfig!: ConfigUtil;
	@Input() entity!: Entity;
	@Input() currentStep!: string;
	@Input() componentClassList?: Array<string>;
	@Input() remainingPercentage?: number
	configQuestionType: typeof ConfigQuestionType = ConfigQuestionType;
	constructor() {}
}
