import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
	APP_ROUTES,
	CORPORATE_ROUTES,
	INDIVIDUAL_ROUTES, MANAGEMENT_ROUTES
} from './core/constants/enum.const';
import { OtpComponent } from './modules/pages/_general/otp/otp.component';
import { WelcomeComponent } from './modules/pages/corporate/welcome/welcome.component';
import { PersonalDetailsComponent } from './modules/pages/corporate/personal-details/personal-details.component';
import { LegalEntityDetailsComponent } from './modules/pages/corporate/legal-entity-details/legal-entity-details.component';
import { EntityRegisteredAddressComponent } from './modules/pages/corporate/entity-registered-address/entity-registered-address.component';
import { DirectorsInformationComponent } from './modules/pages/corporate/directors-information/directors-information.component';
import { ShareholdersComponent } from './modules/pages/corporate/shareholders/shareholders.component';
import { AccountDetailsComponent } from './modules/pages/corporate/account-details/account-details.component';
import { RegulatoryInfoComponent } from './modules/pages/corporate/due-diligence/children/regulatory-info/regulatory-info.component';
import { AmlPoliciesProceduresComponent } from './modules/pages/corporate/due-diligence/children/aml-policies-procedures/aml-policies-procedures.component';
import { BoardSeniorManagementComponent } from './modules/pages/corporate/due-diligence/children/board-senior-management/board-senior-management.component';
import { AmlOfficerComponent } from './modules/pages/corporate/due-diligence/children/aml-officer/aml-officer.component';
import { ComplianceProceduresComponent } from './modules/pages/corporate/due-diligence/children/compliance-procedures/compliance-procedures.component';
import { KycProceduresComponent } from './modules/pages/corporate/due-diligence/children/kyc-procedures/kyc-procedures.component';
import { PepSanctionsCheckComponent } from './modules/pages/corporate/due-diligence/children/pep-sanctions-check/pep-sanctions-check.component';
import { ScamClaimComponent } from './modules/pages/corporate/scam-claim/scam-claim.component';
import { UploadDocumentsComponent } from './modules/pages/corporate/upload-documents/upload-documents.component';
import { ReviewSubmitComponent } from './modules/pages/corporate/review-submit/review-submit.component';
import { AuthComponent } from './modules/pages/_general/auth/auth.component';
import { LastlyComponent } from './modules/pages/corporate/due-diligence/children/lastly/lastly.component';
import { CryptoExchangeComponent } from './modules/pages/corporate/due-diligence/children/crypto-exchange/crypto-exchange.component';
import { AmlLiteComponent } from './modules/pages/corporate/due-diligence/children/aml-lite/aml-lite.component';
import { CompleteComponent } from './modules/pages/_general/complete/complete.component';
import { ErrorComponent } from './modules/pages/_general/error/error.component';
import { SourceOfFundsComponent } from './modules/pages/individual/source-of-funds/source-of-funds.component';
import { SourceOfFundsComponent as CorporateSourceOfFundsComponent } from './modules/pages/corporate/source-of-funds/source-of-funds.component';
import { VerificationDocumentsComponent } from './modules/pages/individual/verification-documents/verification-documents.component';
import { AuthGuard } from './core/guards/auth.guard';
import { OnboardingHomeComponent } from './modules/pages/_general/onboarding-home/onboarding-home.component';
import { CompleteLaterComponent } from './modules/pages/_general/complete-later/complete-later.component';
import { UnsavedChangesGuard } from './core/guards/unsaved-changes.guard';
import { IndividualPersonalDetailsComponent } from './modules/pages/individual/personal-details/personal-details.component';
import { IndividualWelcomeComponent } from './modules/pages/individual/welcome/welcome.component';
import { IndividualEntityRegisteredAddressComponent } from './modules/pages/individual/entity-registered-address/entity-registered-address.component';
import { ReviewLegalComponent } from './modules/pages/individual/review-legal/review-legal.component';
import { NavigationGuard } from './core/guards/navigation.guard';
import { BusinessModelComponent } from './modules/pages/corporate/due-diligence/children/business-model/business-model.component';
import { YieldComponent } from './modules/pages/corporate/yield/yield.component';
import { TaxFormsComponent } from './modules/pages/corporate/tax-forms/tax-forms.component';
import { TransactionMonitoringFiatComponent } from './modules/pages/corporate/due-diligence/children/transaction-monitoring-fiat/transaction-monitoring-fiat.component';
import { TransactionMonitoringCryptoComponent } from './modules/pages/corporate/due-diligence/children/transaction-monitoring-crypto/transaction-monitoring-crypto.component';
import { BlockchainCryptoComponent } from './modules/pages/corporate/due-diligence/children/blockchain-crypto/blockchain-crypto.component';
import { TransactionMonitoringComponent } from './modules/pages/corporate/due-diligence/children/transaction-monitoring/transaction-monitoring.component';
import { ContactDetailsComponent } from './modules/pages/corporate/contact-details/contact-details.component';
import {
	AdditionalContactsComponent
} from './modules/pages/_general/management/additional-contacts/additional-contacts.component';
import { LoginComponent } from './modules/pages/_general/login/login.component';
import {
	FinancialPromotionsAttestationComponent
} from './modules/pages/corporate/due-diligence/children/financial-promotions-attestation/financial-promotions-attestation.component';

const corporateOnboardingRoutes: Routes = [
	{
		path: CORPORATE_ROUTES.welcome,
		canActivate: [AuthGuard],
		component: WelcomeComponent,
	},
	{
		path: CORPORATE_ROUTES.entity,
		canActivate: [AuthGuard],
		canDeactivate: [UnsavedChangesGuard],
		component: PersonalDetailsComponent,
	},
	{
		path: CORPORATE_ROUTES.profile,
		canActivate: [AuthGuard],
		canDeactivate: [UnsavedChangesGuard],
		component: LegalEntityDetailsComponent,
	},
	{
		path: CORPORATE_ROUTES.address,
		canActivate: [AuthGuard],
		canDeactivate: [UnsavedChangesGuard],
		component: EntityRegisteredAddressComponent,
	},
	{
		path: CORPORATE_ROUTES.contacts,
		canActivate: [AuthGuard],
		canDeactivate: [UnsavedChangesGuard],
		component: ContactDetailsComponent,
	},
	{
		path: CORPORATE_ROUTES.directors,
		canActivate: [AuthGuard],
		canDeactivate: [UnsavedChangesGuard],
		component: DirectorsInformationComponent,
	},
	{
		path: CORPORATE_ROUTES.shareholders,
		canActivate: [AuthGuard],
		canDeactivate: [UnsavedChangesGuard],
		component: ShareholdersComponent,
	},
	{
		path: CORPORATE_ROUTES.products,
		canActivate: [AuthGuard],
		canDeactivate: [UnsavedChangesGuard],
		component: AccountDetailsComponent,
	},
	{
		path: CORPORATE_ROUTES.yield,
		canActivate: [AuthGuard],
		canDeactivate: [UnsavedChangesGuard],
		component: YieldComponent,
	},
	{
		path: CORPORATE_ROUTES.source_of_funds,
		canActivate: [AuthGuard],
		canDeactivate: [UnsavedChangesGuard],
		component: CorporateSourceOfFundsComponent,
	},
	{
		path: CORPORATE_ROUTES.due_diligence_form,
		canActivate: [AuthGuard],
		canDeactivate: [UnsavedChangesGuard],
		component: RegulatoryInfoComponent,
	},
	// START due diligence children
	{
		path: CORPORATE_ROUTES.regulatoryInformation,
		canActivate: [AuthGuard],
		canDeactivate: [UnsavedChangesGuard],
		component: RegulatoryInfoComponent,
	},
	{
		path: CORPORATE_ROUTES.financialPromotionsAttestation,
		canActivate: [AuthGuard],
		canDeactivate: [UnsavedChangesGuard],
		component: FinancialPromotionsAttestationComponent,
	},
	{
		path: CORPORATE_ROUTES.antiMoneyLaunderingLite,
		canActivate: [AuthGuard],
		canDeactivate: [UnsavedChangesGuard],
		component: AmlLiteComponent,
	},
	{
		path: CORPORATE_ROUTES.antiMoneyLaunderingPoliciesAndProcedures,
		canActivate: [AuthGuard],
		canDeactivate: [UnsavedChangesGuard],
		component: AmlPoliciesProceduresComponent,
	},
	{
		path: CORPORATE_ROUTES.boardAndSeniorManagement,
		canActivate: [AuthGuard],
		canDeactivate: [UnsavedChangesGuard],
		component: BoardSeniorManagementComponent,
	},
	{
		path: CORPORATE_ROUTES.antiMoneyLaunderingOfficer,
		canActivate: [AuthGuard],
		canDeactivate: [UnsavedChangesGuard],
		component: AmlOfficerComponent,
	},
	{
		path: CORPORATE_ROUTES.ComplianceSystemsAndProcedures,
		canActivate: [AuthGuard],
		canDeactivate: [UnsavedChangesGuard],
		component: ComplianceProceduresComponent,
	},
	{
		path: CORPORATE_ROUTES.businessModelAndCustomerBase,
		canActivate: [AuthGuard],
		canDeactivate: [UnsavedChangesGuard],
		component: BusinessModelComponent,
	},
	{
		path: CORPORATE_ROUTES.kycProcedures,
		canActivate: [AuthGuard],
		canDeactivate: [UnsavedChangesGuard],
		component: KycProceduresComponent,
	},
	{
		path: CORPORATE_ROUTES.pepSanctionsChecks,
		canActivate: [AuthGuard],
		canDeactivate: [UnsavedChangesGuard],
		component: PepSanctionsCheckComponent,
	},
	{
		path: CORPORATE_ROUTES.transactionMonitoring,
		canActivate: [AuthGuard],
		canDeactivate: [UnsavedChangesGuard],
		component: TransactionMonitoringComponent,
	},
	{
		path: CORPORATE_ROUTES.transactionMonitoringFiat,
		canActivate: [AuthGuard],
		canDeactivate: [UnsavedChangesGuard],
		component: TransactionMonitoringFiatComponent,
	},
	{
		path: CORPORATE_ROUTES.transactionMonitoringCrypto,
		canActivate: [AuthGuard],
		canDeactivate: [UnsavedChangesGuard],
		component: TransactionMonitoringCryptoComponent,
	},
	{
		path: CORPORATE_ROUTES.cryptoExchanges,
		canActivate: [AuthGuard],
		canDeactivate: [UnsavedChangesGuard],
		component: CryptoExchangeComponent,
	},
	{
		path: CORPORATE_ROUTES.blockchainCrypto,
		canActivate: [AuthGuard],
		canDeactivate: [UnsavedChangesGuard],
		component: BlockchainCryptoComponent,
	},
	{
		path: CORPORATE_ROUTES.lastly,
		canActivate: [AuthGuard],
		canDeactivate: [UnsavedChangesGuard],
		component: LastlyComponent,
	},
	// END due diligence children
	{
		path: CORPORATE_ROUTES.scamClaim,
		canActivate: [AuthGuard],
		canDeactivate: [UnsavedChangesGuard],
		component: ScamClaimComponent,
	},
	{
		path: CORPORATE_ROUTES.documents,
		canActivate: [AuthGuard],
		canDeactivate: [UnsavedChangesGuard],
		component: UploadDocumentsComponent,
	},
	{
		path: CORPORATE_ROUTES.tax_forms,
		canActivate: [AuthGuard],
		canDeactivate: [UnsavedChangesGuard],
		component: TaxFormsComponent,
	},
	{
		path: CORPORATE_ROUTES.terms,
		canActivate: [AuthGuard],
		canDeactivate: [UnsavedChangesGuard],
		component: ReviewSubmitComponent,
	},
	{
		path: '',
		pathMatch: 'full',
		redirectTo: `${APP_ROUTES.error}/404`,
	},
]
const individualOnboardingRoutes: Routes = [
	{
		path: INDIVIDUAL_ROUTES.welcome,
		canActivate: [AuthGuard],
		component: IndividualWelcomeComponent,
	},
	{
		path: INDIVIDUAL_ROUTES.profile,
		canActivate: [AuthGuard],
		canDeactivate: [UnsavedChangesGuard],
		component: IndividualPersonalDetailsComponent,
	},
	{
		path: INDIVIDUAL_ROUTES.address,
		canActivate: [AuthGuard],
		canDeactivate: [UnsavedChangesGuard],
		component: IndividualEntityRegisteredAddressComponent,
	},
	{
		path: INDIVIDUAL_ROUTES.verificationDocuments,
		canActivate: [AuthGuard],
		canDeactivate: [UnsavedChangesGuard],
		component: VerificationDocumentsComponent,
	},
	{
		path: INDIVIDUAL_ROUTES.sourceOfFunds,
		canActivate: [AuthGuard],
		canDeactivate: [UnsavedChangesGuard],
		component: SourceOfFundsComponent,
	},
	{
		path: INDIVIDUAL_ROUTES.terms,
		canActivate: [AuthGuard],
		canDeactivate: [UnsavedChangesGuard],
		component: ReviewLegalComponent,
	},
]
const managementRoutes: Routes = [
	{
		path: MANAGEMENT_ROUTES.additional_contacts,
		canActivate: [AuthGuard],
		canDeactivate: [UnsavedChangesGuard],
		component: AdditionalContactsComponent,
	},
]

const generalRoutes: Routes = [
	{
		path: '',
		pathMatch: 'full',
		component: AuthComponent,
	},
	{
		path: APP_ROUTES.home,
		component: OnboardingHomeComponent,
	},
	{
		path: APP_ROUTES.completeLater,
		component: CompleteLaterComponent,
	},
	{
		path: APP_ROUTES.completed,
		component: CompleteComponent,
	},
	{
		path: APP_ROUTES.otp,
		component: OtpComponent,
	},
	{
		path: `${APP_ROUTES.login}/:entity_id`,
		component: LoginComponent,
	},
	{
		path: `${APP_ROUTES.error}/:status`,
		component: ErrorComponent,
	}
]
const onboardingRoutes: Routes = [
	{
		path: `${APP_ROUTES.onboarding}`,
		children: [
			{
				path: 'verify/:entity_id',
				component: LoginComponent,
			},
			{
				path: 'verify/:token/:entity_id',
				component: OtpComponent,
			},
			{
				path: CORPORATE_ROUTES.base,
				canActivateChild: [NavigationGuard],
				children: [
					...corporateOnboardingRoutes,
					...managementRoutes
				],
			},
			{
				path: INDIVIDUAL_ROUTES.base,
				canActivateChild: [NavigationGuard],
				children: [
					...individualOnboardingRoutes,
					...managementRoutes
				],
			},
		],
	},
]

const routes: Routes = [
	...generalRoutes,
	...onboardingRoutes,
	{
		path: '**',
		pathMatch: 'full',
		redirectTo: `${APP_ROUTES.error}/404`,
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
