<div class="row">
    <ng-container *ngFor="let question of questions">
        <ng-container *ngIf="visibleControls.has(question.key)">
            <ng-container [ngSwitch]="question.type" [formGroup]="formGroup">
                <bcb-generic-checkbox class="lbl-stacked"
                                      [ngClass]="question?.attributes?.classList || componentClassList || 'col-md-12'"
                                      *ngSwitchCase="configQuestionType.CHECKBOX"
                                      [question]="question"
                                      [formGroup]="formGroup"
                                      [formControlName]="question.key"
                                      [errorMessage]="questionsConfig.getErrorMessage(question.key)"
                                      (change)="questionsConfig.onAnswerChange(question, $event)">
                </bcb-generic-checkbox>

                <bcb-generic-checklist class="lbl-stacked"
                                       [ngClass]="question?.attributes?.classList || componentClassList || 'col-md-12'"
                                       *ngSwitchCase="configQuestionType.CHECKLIST"
                                       [question]="question"
                                       [formGroup]="formGroup"
                                       [formControlName]="question.key"
                                       [errorMessage]="questionsConfig.getErrorMessage(question.key)"
                                       (change)="questionsConfig.onAnswerChange(question, $event)">
                </bcb-generic-checklist>

                <bcb-generic-component-group class="lbl-stacked"
                                             [ngClass]="question?.attributes?.classList || componentClassList || 'col-md-12'"
                                             *ngSwitchCase="configQuestionType.COMPONENT_GROUP"
                                             [formGroup]="formGroup"
                                             [question]="question"
                                             [questionsConfig]="questionsConfig">
                </bcb-generic-component-group>

                <bcb-generic-country-select class="lbl-stacked"
                                            [ngClass]="question?.attributes?.classList || componentClassList || 'col-md-12'"
                                            *ngSwitchCase="configQuestionType.COUNTRY_SELECT"
                                            [question]="question"
                                            [formGroup]="formGroup"
                                            [formControlName]="question.key"
                                            [errorMessage]="questionsConfig.getErrorMessage(question.key)"
                                            (change)="questionsConfig.onAnswerChange(question, $event)">
                </bcb-generic-country-select>

                <bcb-generic-currency class="lbl-stacked"
                                      [ngClass]="question?.attributes?.classList || componentClassList || 'col-md-12'"
                                      *ngSwitchCase="configQuestionType.CURRENCY"
                                      [question]="question"
                                      [formGroup]="formGroup"
                                      [formGroupName]="question.key"
                                      [errorMessage]="questionsConfig.getErrorMessage(question.key)"
                                      (change)="questionsConfig.onAnswerChange(question, $event)">
                </bcb-generic-currency>

                <bcb-generic-date class="lbl-stacked"
                                  [ngClass]="question?.attributes?.classList || componentClassList || 'col-md-12'"
                                  *ngSwitchCase="configQuestionType.DATE"
                                  [question]="question"
                                  [formGroup]="formGroup"
                                  [formControlName]="question.key"
                                  [errorMessage]="questionsConfig.getErrorMessage(question.key)"
                                  (change)="questionsConfig.onAnswerChange(question, $event)">
                </bcb-generic-date>

                <bcb-generic-file class="lbl-stacked"
                                  [ngClass]="question?.attributes?.classList || componentClassList || 'col-md-12'"
                                  *ngSwitchCase="configQuestionType.FILE"
                                  [question]="question"
                                  [entityId]="entity.id"
                                  [fileTypePrefix]="currentStep"
                                  [formGroup]="formGroup"
                                  [formControlName]="question.key"
                                  [errorMessage]="questionsConfig.getErrorMessage(question.key)"
                                  (change)="questionsConfig.handleValueChange(question)"
                                  (uploadedFiles)="questionsConfig.fileControlValues[question.key]=$event; questionsConfig.onAnswerChange(question, $event)"
                                  (uploadSkipped)="questionsConfig.fileControlValues[question.key]=$event; questionsConfig.onAnswerChange(question, $event)">
                </bcb-generic-file>

                <bcb-generic-label class="lbl-stacked"
                                   [ngClass]="question?.attributes?.classList || componentClassList || 'col-md-12'"
                                   *ngSwitchCase="configQuestionType.LABEL"
                                   [question]="question">
                </bcb-generic-label>

                <bcb-generic-input class="lbl-stacked"
                                   [ngClass]="question?.attributes?.classList || componentClassList || 'col-md-12'"
                                   *ngSwitchCase="configQuestionType.INPUT"
                                   [question]="question"
                                   [formGroup]="formGroup"
                                   [formControlName]="question.key"
                                   [errorMessage]="questionsConfig.getErrorMessage(question.key)"
                                   (change)="questionsConfig.onAnswerChange(question, $event.target.value)">
                </bcb-generic-input>

                <bcb-generic-number-input class="lbl-stacked"
                                   [ngClass]="question?.attributes?.classList || componentClassList || 'col-md-12'"
                                   *ngSwitchCase="configQuestionType.NUMBER"
                                   [question]="question"
                                   [formGroup]="formGroup"
                                   [formControlName]="question.key"
                                   [errorMessage]="questionsConfig.getErrorMessage(question.key)"
                                   (change)="questionsConfig.onAnswerChange(question, $event.target.value)">
                </bcb-generic-number-input>

                <bcb-generic-mobile class="lbl-stacked"
                                    [ngClass]="question?.attributes?.classList || componentClassList || 'col-md-12'"
                                    *ngSwitchCase="configQuestionType.MOBILE"
                                    [question]="question"
									[formGroup]="formGroup"
                                    [formControlName]="question.key"
                                    [errorMessage]="questionsConfig.getErrorMessage(question.key)"
                                    (change)="questionsConfig.onAnswerChange(question, $event.target.value)">
                </bcb-generic-mobile>

                <bcb-generic-percentage class="lbl-stacked"
                                        [ngClass]="question?.attributes?.classList || componentClassList || 'col-md-12'"
                                        *ngSwitchCase="configQuestionType.PERCENTAGE"
                                        [question]="question"
                                        [formControlName]="question.key"
                                        [remainingPercentage]="remainingPercentage ?? 100"
                                        [shouldCalculate]="false"
                                        [errorMessage]="questionsConfig.getErrorMessage(question.key)"
                                        (change)="questionsConfig.onAnswerChange(question, $event.target.value)">
                </bcb-generic-percentage>

                <bcb-generic-radio-group class="lbl-stacked"
                                         [ngClass]="question?.attributes?.classList || componentClassList || 'col-md-12'"
                                         *ngSwitchCase="configQuestionType.RADIO"
                                         [question]="question"
                                         [formGroup]="formGroup"
                                         [formControlName]="question.key"
                                         [errorMessage]="questionsConfig.getErrorMessage(question.key)"
                                         (change)="questionsConfig.onAnswerChange(question, $event)">
                </bcb-generic-radio-group>

                <bcb-generic-select class="lbl-stacked"
                                    [ngClass]="question?.attributes?.classList || componentClassList || 'col-md-12'"
                                    *ngSwitchCase="configQuestionType.SELECT"
                                    [question]="question"
                                    [formGroup]="formGroup"
                                    [formControlName]="question.key"
                                    [errorMessage]="questionsConfig.getErrorMessage(question.key)"
                                    (change)="questionsConfig.onAnswerChange(question, $event)">
                </bcb-generic-select>

                <bcb-generic-text-area class="lbl-stacked"
                                       [ngClass]="question?.attributes?.classList || componentClassList || 'col-md-12'"
                                       *ngSwitchCase="configQuestionType.TEXT"
                                       [question]="question"
                                       [formGroup]="formGroup"
                                       [formControlName]="question.key"
                                       [errorMessage]="questionsConfig.getErrorMessage(question.key)"
                                       (change)="questionsConfig.onAnswerChange(question, $event.target.value)">
                </bcb-generic-text-area>
            </ng-container>
        </ng-container>
    </ng-container>
</div>