<mat-label *ngIf="!question.attributes?.hideLabel">
	<span [innerHtml]="question.label"></span>
	<ng-container *ngIf="question.tooltip">
		<button class="btn-text-only" (click)="showTooltip = !showTooltip" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
			<i *ngIf="question.tooltip?.label; else genericInfoLabel">{{ question.tooltip?.label }}</i>
		</button>
		<ng-template
			cdkConnectedOverlay
			[cdkConnectedOverlayOrigin]="trigger"
			[cdkConnectedOverlayOpen]="showTooltip"
			(detach)="showTooltip = false"
			(overlayOutsideClick)="showTooltip = false">
			<div class="pre-line tooltip-content" [innerHtml]="question.tooltip?.value"></div>
		</ng-template>
	</ng-container>
	<small *ngIf="question.subLabel"><br><i>{{ question.subLabel }}</i></small>
	<a *ngIf="question.link as link" [download]="link.title" [href]="link.url">{{ link.label }}</a>
</mat-label>

<ng-template #genericInfoLabel><span>&#9432;</span></ng-template>