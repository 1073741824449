import { Component, Input } from '@angular/core';
import { ConfigQuestion } from '../../models/config-question.interface';

@Component({
	selector: 'bcb-generic-label',
	templateUrl: './generic-label.component.html',
	styleUrls: ['./generic-label.component.scss'],
})
export class GenericLabelComponent {
	@Input() question!: ConfigQuestion;
	showTooltip: boolean = false;

	constructor() {}
}
