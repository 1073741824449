import { ConfigQuestion } from '../models/config-question.interface';
import {
	AbstractControl,
	FormControl,
	FormGroup,
	ValidationErrors,
	ValidatorFn,
	Validators,
} from '@angular/forms';
import { ConfigQuestionType, ERROR_MESSAGES } from '../constants/enum.const';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import * as uuid from 'uuid';
import { emailPattern } from '../constants/regex.const';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { ShowWhenRecord } from '../models/config-question-attributes.interface';

export class ConfigUtil {
	subscriptions: Subscription = new Subscription();
	unsubscribe: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	unsubscribe$: Subject<boolean> = new Subject<boolean>();
	fileControlValues: Record<string, Array<any>> = {};
	questions!: BehaviorSubject<Array<ConfigQuestion>>;
	onAnswerReceived$: Subject<{ question: ConfigQuestion; value: any }> =
		new Subject<{ question: ConfigQuestion; value: any }>();
	private _groupedComponents: { [key: string]: Array<string> } = {};

	constructor(private readonly configQuestions: Array<ConfigQuestion>) {
		this.questions = new BehaviorSubject<Array<ConfigQuestion>>(
			configQuestions
		);
		this.initializeFormControls();

		// set visibility of the questions based on their `null` value
		this.configQuestions.forEach((question) =>
			this.handleValueChange(question)
		);
	}

	private _showWhenQuestions: Map<string, any> = new Map<string, any>();

	get showWhenQuestions(): Map<string, any> {
		return this._showWhenQuestions;
	}

	private _validatorQuestions: Record<string, Array<ConfigQuestion>> = {};

	get validatorQuestions(): Record<string, Array<ConfigQuestion>> {
		return this._validatorQuestions;
	}

	private _validators: { [key: string]: Array<ValidatorFn> } = {};

	get validators(): { [p: string]: Array<ValidatorFn> } {
		return this._validators;
	}

	private _formGroup!: FormGroup;

	get formGroup(): FormGroup {
		return this._formGroup;
	}

	private _visibleControls: Set<string> = new Set<string>();

	get visibleControls(): Set<string> {
		return this._visibleControls;
	}

	private _requiredControls: Set<string> = new Set<string>();

	get requiredControls(): Set<string> {
		return this._requiredControls;
	}

	setDefaultValues(stepData: Record<string, any>): void {
		const _questions = this.questions.value;
		for (let index = 0; index < this.questions.value.length; index++) {
			let question = this.questions.value[index];
			if (stepData) {
				try {
					switch (question.type) {
						case ConfigQuestionType.COMPONENT_GROUP:
							_questions[index] = this.autoGenerateFor(question);
							this.questions.next(_questions);
							break;
						case ConfigQuestionType.FILE:
							if (stepData[question.key]) {
								if (
									!stepData[question.key].hasOwnProperty(
										'skipped'
									)
								) {
									this.fileControlValues[question.key] =
										stepData[question.key];
									this.updateFileControl(question.key);
								}
							}
							break;
					}
					this.formGroup
						?.get(question.key)
						?.setValue(stepData[question.key], {
							emitEvent: false,
						});
				} catch (e) {
					console.error(e);
				}
			}

			this.handleValueChange(question);
		}
	}

	handleValueChange(question: ConfigQuestion): void {
		this.handleComponentShowHide(question);
		this.handleComponentAutoGeneration(question);
		this.validatorQuestions[question.key]?.forEach(
			(_question: ConfigQuestion) => {
				const _control = this.formGroup.get(_question.key);
				_control?.setValidators(this.constructValidators(_question));
				_control?.updateValueAndValidity();
			}
		);
	}

	onAnswerChange(question: ConfigQuestion, value: any): void {
		this.handleValueChange(question);
		if (typeof value !== 'boolean') {
			if (
				value === undefined ||
				value === null ||
				(Array.isArray(value) && !value.length) ||
				(!Array.isArray(value) && Object.values(value).length) === 0
			) {
				value = undefined;
			}
		}
		if (
			this.formGroup.get(question.key)?.valid ||
			value === undefined ||
			question.type === ConfigQuestionType.FILE
		) {
			this.onAnswerReceived$.next({ question, value });
		}
	}

	getErrorMessage(formControlName: string): ERROR_MESSAGES | undefined {
		const control = this.formGroup.get(formControlName);
		if (control?.touched && control.errors) {
			// Using a loop to get the first index O(1)
			for (let key in control.errors) {
				switch (key) {
					case 'min':
						return ERROR_MESSAGES.MIN;
					case 'max':
						return ERROR_MESSAGES.MAX;
					case 'required':
						return ERROR_MESSAGES.REQUIRED;
					case 'requiredtrue':
						return ERROR_MESSAGES.REQUIRED_TRUE;
					case 'email':
						return ERROR_MESSAGES.EMAIL;
					case 'minlength':
						return ERROR_MESSAGES.MIN_LENGTH;
					case 'maxlength':
						return ERROR_MESSAGES.MAX_LENGTH;
					case 'pattern':
						return ERROR_MESSAGES.PATTERN;
					case 'matchesComponentValue':
						return ERROR_MESSAGES.MATCHES_COMPONENT_VALUE;
					case 'requiredIfComponentHasOneOf':
						return ERROR_MESSAGES.REQUIRED;
					case 'mobileNumber':
						return ERROR_MESSAGES.PATTERN;
				}
				// Exiting the loop since we only want the first key
				break;
			}
		}
		return undefined;
	}

	getFormValuesMap(): Record<string, any> {
		const values: Record<string, any> = {};
		const formValue = this.formGroup.value;

		for (let question of this.questions.value.filter((item) =>
			this.visibleControls.has(item.key)
		)) {
			values[question.key] = formValue[question.key];

			switch (question.type) {
				case ConfigQuestionType.FILE:
					if (!formValue[question.key]?.hasOwnProperty('skipped')) {
						values[question.key] =
							this.fileControlValues[question.key];
					}
					break;
			}
		}

		return values;
	}

	initializeFormControls(): void {
		this._formGroup = new FormGroup({});
		const _questions = [...this.questions.value];

		for (let question of _questions) {
			switch (question.type) {
				case ConfigQuestionType.CURRENCY:
					this.formGroup.addControl(
						question.key,
						new FormGroup({
							currency: new FormControl(),
							amount: new FormControl(),
						})
					);
					break;
				case ConfigQuestionType.COMPONENT_GROUP:
					const associatedComponent =
						question.attributes?.autoGenerateFor;
					if (!associatedComponent) {
						break;
					}
					if (!this._groupedComponents[associatedComponent]) {
						this._groupedComponents[associatedComponent] = [];
					}
					this._groupedComponents[associatedComponent] = [
						...this._groupedComponents[associatedComponent],
						question.key,
					];
					const questionIndex = this.questions.value.findIndex(
						(item) => item.key === question.key
					);
					_questions[questionIndex] = this.autoGenerateFor(question);
					break;
				case ConfigQuestionType.CHECKLIST:
					const checklistGroup: FormGroup = new FormGroup({});
					if (!question.options) {
						throw new Error(
							`Please ensure that options are provided for type ${question.type}`
						);
					}

					for (let option of question.options) {
						if ('key' in option && option.key) {
							checklistGroup.addControl(
								option.key,
								new FormControl(undefined)
							);
						} else {
							throw new Error(
								`[key] is required for option in the checklist`
							);
						}
					}

					this.formGroup.addControl(question.key, checklistGroup);
					break;
				default:
					this.formGroup.addControl(
						question.key,
						new FormControl(undefined)
					);
					break;
			}

			if (question.attributes?.showWhen) {
				Object.keys(question.attributes?.showWhen).forEach((key) => {
					this._showWhenQuestions.set(key, {
						...this._showWhenQuestions.get(key),
						[question.key]: question,
					});
				});
			}

			if (question.validators?.requiredIfComponentHasOneOf) {
				const _value = this.validatorQuestions[question.key] ?? [];
				this._validatorQuestions[
					question.validators?.requiredIfComponentHasOneOf.controlKey
				] = [..._value, question];
			}

			this._validators[question.key] = this.constructValidators(question);

			switch (question.type) {
				case ConfigQuestionType.CURRENCY:
					this.formGroup
						.get(question.key)
						?.get('amount')
						?.setValidators(this._validators[question.key]);
					this.formGroup
						.get(question.key)
						?.get('currency')
						?.setValidators(this._validators[question.key]);
					break;
				default:
					this.formGroup
						.get(question.key)
						?.setValidators(this._validators[question.key]);
					break;
			}

			this._visibleControls.add(question.key);

			if (question.validators?.required) {
				this._requiredControls.add(question.key);
			}

			if (question.attributes?.disabled) {
				this.formGroup.get(question.key)?.disable();
			}

			this.formGroup
				.get(question.key)
				?.valueChanges.pipe(
					takeUntil(this.unsubscribe$),
					tap(() => this.handleValueChange(question))
				)
				.subscribe();
		}
		this.questions.next(_questions);
	}

	private handleComponentAutoGeneration(question: ConfigQuestion): void {
		const _components = this._groupedComponents[question.key];
		if (_components) {
			const _questions = this.questions.value;
			for (let component of _components) {
				const questionIndex = this.questions.value.findIndex(
					(item) => item.key === component
				);
				if (_questions[questionIndex]) {
					_questions[questionIndex] = this.autoGenerateFor(
						_questions[questionIndex]
					);
				}
			}
			this.questions.next(_questions);
		}
	}

	private handleComponentShowHide(question: ConfigQuestion): void {
		const showWhen = this.showWhenQuestions.get(question.key);
		if (!showWhen) {
			return;
		}

		Object.keys(showWhen)?.forEach((_key) => {
			if (this.shouldShow(showWhen[_key], question)) {
				switch (showWhen[_key].type) {
					case ConfigQuestionType.CURRENCY:
						this.formGroup
							.get(_key)
							?.get('amount')
							?.setValidators(this.validators[_key]);
						this.formGroup
							.get(_key)
							?.get('currency')
							?.setValidators(this.validators[_key]);
						break;
					default:
						this.formGroup
							.get(_key)
							?.setValidators(this.validators[_key]);
						break;
				}
				this.formGroup.get(_key)?.updateValueAndValidity();
				this.visibleControls.add(_key);
			} else {
				switch (showWhen[_key].type) {
					case ConfigQuestionType.CURRENCY:
						this.formGroup
							.get(_key)
							?.get('amount')
							?.clearValidators();
						this.formGroup
							.get(_key)
							?.get('currency')
							?.clearValidators();
						break;
					default:
						this.formGroup.get(_key)?.clearValidators();
						break;
				}
				this.formGroup.get(_key)?.updateValueAndValidity();
				this.formGroup.get(_key)?.reset();
				this.visibleControls.delete(_key);
			}
		});
	}

	private constructValidators(question: ConfigQuestion): Array<ValidatorFn> {
		const _validators = [];
		if (question.validators?.min) {
			_validators.push(Validators.min(question.validators?.min));
		}
		if (question.validators?.max) {
			_validators.push(Validators.max(question.validators?.max));
		}
		if (question.validators?.required) {
			_validators.push(Validators.required);
		}
		if (question.validators?.requiredTrue) {
			_validators.push(Validators.requiredTrue);
		}
		if (question.validators?.email) {
			_validators.push(Validators.pattern(emailPattern));
		}
		if (question.validators?.minLength) {
			_validators.push(
				Validators.minLength(question.validators?.minLength)
			);
		}
		if (question.validators?.maxLength) {
			_validators.push(
				Validators.maxLength(question.validators?.maxLength)
			);
		}
		if (question.validators?.pattern) {
			_validators.push(Validators.pattern(question.validators?.pattern));
		}
		if (question.validators?.matchesComponentValue) {
			_validators.push(
				this.matchesComponentValueValidator(
					question.validators?.matchesComponentValue
				)
			);
		}
		if (question.validators?.requiredIfComponentHasOneOf) {
			_validators.push(
				this.requiredIfComponentHasOneOf(
					question.validators?.requiredIfComponentHasOneOf.controlKey,
					question.validators?.requiredIfComponentHasOneOf
						.compareValues,
					question.validators?.requiredIfComponentHasOneOf
						.compareField
				)
			);
		}
		if (question.type === ConfigQuestionType.MOBILE) {
			_validators.push(this.mobileNumberValidator());
		}

		return _validators;
	}

	private shouldShow(
		component: any,
		linkedComponent: ConfigQuestion
	): boolean {
		const componentShowWhen: ShowWhenRecord =
			component.attributes.showWhen[linkedComponent.key];

		if (component.attributes.matchConditions === 'SOME') {
			return Object.keys(componentShowWhen).some((key: string) =>
				this.shouldShowCondition(
					key,
					linkedComponent,
					componentShowWhen
				)
			);
		}

		return Object.keys(componentShowWhen).every((key: string) =>
			this.shouldShowCondition(key, linkedComponent, componentShowWhen)
		);
	}

	private shouldShowCondition(
		key: string,
		linkedComponent: ConfigQuestion,
		showWhenRecord: ShowWhenRecord
	): boolean {
		const value = this.formGroup.get(linkedComponent.key)?.value;
		switch (key) {
			case 'isNotEmpty':
				return !!this.formGroup.get(linkedComponent.key)?.value;
			case 'hasValue':
				return this.hasValue(this.formGroup.get(linkedComponent.key)?.value, showWhenRecord.hasValue);
			case 'hasValueOtherThan':
				return !this.hasValue(this.formGroup.get(linkedComponent.key)?.value, showWhenRecord.hasValueOtherThan);
			case 'hasValueAtPath':
				if (!showWhenRecord.hasValueAtPath) {
					return false;
				}
				const _hasValuePath = showWhenRecord.hasValueAtPath?.path;

				if (
					Array.isArray(
						this.formGroup.get(linkedComponent.key)?.value
					)
				) {
					return this.formGroup
						.get(linkedComponent.key)
						?.value?.some(
							(item: any) =>
								item[_hasValuePath] ===
								showWhenRecord.hasValueAtPath?.value
						);
				}

				return (
					this.formGroup.get(linkedComponent.key)?.value?.[
						_hasValuePath
					] === showWhenRecord.hasValueAtPath?.value
				);
			case 'hasOneOf':
				if(!showWhenRecord.hasOneOf?.length) {
					return false
				}
				if(!value || !value?.length) {
					return false
				}

				return this.hasOneOf(value, showWhenRecord.hasOneOf);
			case 'hasNoneOf':
				if(!showWhenRecord.hasNoneOf?.length) {
					return false
				}
				if(!value || !value?.length) {
					return false
				}

				return !this.hasOneOf(value, showWhenRecord.hasNoneOf);
			case 'greaterThan':
				if (!showWhenRecord.greaterThan) {
					return false;
				}
				switch (linkedComponent.type) {
					case ConfigQuestionType.DATE:
						return (
							new Date(
								this.formGroup.get(linkedComponent.key)?.value
							) > new Date(showWhenRecord.greaterThan)
						);
					default:
						return (
							this.formGroup.get(linkedComponent.key)?.value >
							showWhenRecord.greaterThan
						);
				}
			default:
				console.error('Unhandled condition [%s]', key);
				return false;
		}
	}

	private autoGenerateFor(question: ConfigQuestion): ConfigQuestion {
		const _question: ConfigQuestion = { ...question };
		if (!_question.componentTemplate) {
			throw new Error('[componentTemplate] is required');
		}
		if (!_question.attributes?.autoGenerateFor) {
			throw new Error('[attributes].[autoGenerateFor] is required');
		}

		const associatedControl = this.formGroup.get(
			_question.attributes?.autoGenerateFor
		);
		if (
			!associatedControl?.value ||
			!Array.isArray(associatedControl?.value)
		) {
			return _question;
		}

		this.formGroup.removeControl(_question.key);
		this.formGroup.addControl(_question.key, new FormGroup({}));
		let group = this.formGroup.get(_question.key) as FormGroup;

		if (!_question.componentList) {
			_question.componentList = {};
		}

		for (let [index, value] of associatedControl?.value.entries()) {
			const associatedQuestionIndex = this.questions.value.findIndex(
				(item) => item.key === _question.attributes?.autoGenerateFor
			);
			const key = uuid.v5(
				String(index),
				this.questions.value[associatedQuestionIndex].key
			);

			const template = { ..._question.componentTemplate, key };

			switch (this.questions.value[associatedQuestionIndex]?.type) {
				case ConfigQuestionType.COUNTRY_SELECT:
					template.label = value.name;
					break;
				default:
					template.label = value.label;
					break;
			}

			group.addControl(
				key,
				new FormControl(undefined, this.constructValidators(template))
			);

			_question.componentList[key] = template;
		}
		if (Object.keys(group.value)?.length < 1) {
			this._visibleControls.delete(_question.key);
		}
		return _question;
	}

	private updateFileControl(key: string): void {
		const control = this.formGroup.get(key);
		const files = this.fileControlValues[key];
		control?.setValue(files);
	}

	private matchesComponentValueValidator(controlKey: string): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			const controlValue = this.formGroup.get(controlKey)?.value;
			return control.value !== controlValue
				? { matchesComponentValue: { value: control.value } }
				: null;
		};
	}

	private requiredIfComponentHasOneOf(
		controlKey: string,
		compareValues: Array<string>,
		compareField?: string
	): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			const controlValue = this.formGroup.get(controlKey)?.value;
			return !control.value &&
				compareValues.includes(
					compareField
						? controlValue && controlValue[compareField]
						: controlValue
				)
				? { requiredIfComponentHasOneOf: true }
				: null;
		};
	}

	private mobileNumberValidator(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			return control.value &&
				typeof control.value === 'string' &&
				!isValidPhoneNumber(control.value)
				? { mobileNumber: { value: control.value } }
				: null;
		};
	}

	private hasValue(controlValue: any, value: any): boolean {
		if (Array.isArray(controlValue)) {
			return controlValue.map((item) => item?.value ?? item).includes(value);
		}
		if(controlValue?.value) {
			return controlValue?.value === value;
		}
		return controlValue === value;
	}

	private hasOneOf(controlValue: any, values: Array<any>): boolean {
		return values.some((value: any) => {
			if(!controlValue || !controlValue?.length) {
				return false;
			}

			if(Array.isArray(controlValue)) {
				return controlValue.map(item => item.value ?? item).includes(value);
			}
			return (controlValue?.value ?? controlValue) === value;
		}) ?? false
	}
}
