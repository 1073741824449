import { NgModule } from '@angular/core';
import { CommonModule, PercentPipe } from '@angular/common';
import { GenericInputComponent } from './generic-input/generic-input.component';
import { GenericTextAreaComponent } from './generic-text-area/generic-text-area.component';
import { GenericChecklistComponent } from './generic-checklist/generic-checklist.component';
import { GenericRadioGroupComponent } from './generic-radio-group/generic-radio-group.component';
import { GenericCurrencyComponent } from './generic-currency/generic-currency.component';
import { GenericDateComponent } from './generic-date/generic-date.component';
import { GenericFileComponent } from './generic-file/generic-file.component';
import { GenericSelectComponent } from './generic-select/generic-select.component';
import { GenericCountrySelectComponent } from './generic-country-select/generic-country-select.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSliderModule } from '@angular/material/slider';
import { MatBadgeModule } from '@angular/material/badge';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '../pipes/pipes.module';
import { GenericCheckboxComponent } from './generic-checkbox/generic-checkbox.component';
import { GenericLabelComponent } from './generic-label/generic-label.component';
import { GenericPercentageComponent } from './generic-percentage/generic-percentage.component';
import { GenericComponentGroupComponent } from './generic-component-group/generic-component-group.component';
import { GenericMobileComponent } from './generic-mobile/generic-mobile.component';
import { Ng2TelInputModule } from '../directives/ng2-tel-input/ng2-tel-input.module';
import { ComponentWrapperComponent } from './component-wrapper/component-wrapper.component';
import { BasePageComponent } from './component-base-page/base-page.component';
import { MobileInputDirectiveModule } from '../directives/mobile-input/mobile-input.module';
import { GenericNumberInputComponent } from './generic-number-input/generic-number-input.component';
import { OverlayModule } from '@angular/cdk/overlay';

@NgModule({
	declarations: [
		GenericInputComponent,
		GenericNumberInputComponent,
		GenericTextAreaComponent,
		GenericChecklistComponent,
		GenericRadioGroupComponent,
		GenericCurrencyComponent,
		GenericDateComponent,
		GenericFileComponent,
		GenericSelectComponent,
		GenericCountrySelectComponent,
		GenericCheckboxComponent,
		GenericLabelComponent,
		GenericPercentageComponent,
		GenericComponentGroupComponent,
		GenericMobileComponent,
		ComponentWrapperComponent,
		BasePageComponent,
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		MatNativeDateModule,
		MatButtonModule,
		MatIconModule,
		MatFormFieldModule,
		MatInputModule,
		MatDividerModule,
		MatRadioModule,
		MatDatepickerModule,
		MatSelectModule,
		MatSnackBarModule,
		Ng2TelInputModule,
		MatTableModule,
		MatCheckboxModule,
		MatBadgeModule,
		MatTooltipModule,
		MatProgressBarModule,
		MatProgressSpinnerModule,
		MatSliderModule,
		MatCardModule,
		PipesModule,
		MobileInputDirectiveModule,
		OverlayModule,
	],
	exports: [
		GenericInputComponent,
		GenericNumberInputComponent,
		GenericTextAreaComponent,
		GenericChecklistComponent,
		GenericRadioGroupComponent,
		GenericCurrencyComponent,
		GenericDateComponent,
		GenericFileComponent,
		GenericSelectComponent,
		GenericCountrySelectComponent,
		GenericCheckboxComponent,
		GenericLabelComponent,
		GenericPercentageComponent,
		GenericComponentGroupComponent,
		GenericMobileComponent,
		ComponentWrapperComponent,
	],
	providers: [PercentPipe],
})
export class ComponentsModule {}
